import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBwIcfDTe8-GtWN8j9fZdNkInkbSZUnJTw",
  authDomain: "videocallapp-9b8c4.firebaseapp.com",
  projectId: "videocallapp-9b8c4",
  storageBucket: "videocallapp-9b8c4.appspot.com",
  messagingSenderId: "77240876762",
  appId: "1:77240876762:web:0ac5d65ce84b1e8b302fe3"
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const auth = getAuth(app);

export { auth, firestore };
